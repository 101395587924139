export const parseSeconds = (seconds: number | null, extraText = '') => {
  if (seconds === null) {
    return null;
  }

  if (seconds < 60) {
    return `${seconds} secs${extraText ? ' ' : ''}${extraText}`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} mins${extraText ? ' ' : ''}${extraText}`;
  } else {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hrs${extraText ? ' ' : ''}${extraText}`;
  }
};

/**
 *
 * @param page
 * @param size
 * @returns [From, To] -> .range(from, to);
 */
export const getRangePagination = (page: number, size: number) => {
  return [page * size, (page + 1) * size - 1];
};

export const numberOfPages = (count: number, perPage: number) => {
  return Math.ceil(count / perPage);
};

export const getDeviceFromAgent = (agent: string | null) => {
  let os = 'Unknown OS';
  let device = 'Unknown Device';

  if (!agent) {
    return { device, os };
  }

  if (agent.includes('Linux')) os = 'Linux';
  if (agent.includes('X11')) os = 'Unix';

  if (agent.includes('Win')) os = 'Windows';
  if (agent.includes('Mac')) os = 'MacOS';

  if (agent.includes('Android')) os = 'Android';
  if (agent.includes('iPhone')) os = 'iPhone';
  if (agent.includes('iPad')) os = 'iPad';

  if (agent.includes('Mobile') || agent.includes('Android')) {
    device = 'Phone';
  } else {
    device = 'Desktop';
  }

  return {
    device,
    os
  };
};

export const getSessionIdFromToken = (token: string) => {
  return JSON.parse(atob(token.split('.')[1])).session_id;
};

export const getDeviceType = (agent: string | null): 'desktop' | 'mobile' | 'unknown' => {
  if (!agent) {
    return 'unknown';
  }

  const userAgent = agent.toLowerCase();
  if (userAgent.includes('mobile') || userAgent.includes('android')) {
    return 'mobile';
  }

  return 'desktop';
};

export const getBrowserType = (agent: string | null) => {
  if (!agent) {
    return 'unknown' as const;
  }

  const userAgent = agent.toLowerCase();

  if (userAgent.includes('edg/')) {
    return 'edge-chromium' as const;
  } else if (userAgent.includes('edge')) {
    return 'edge' as const;
  } else if (userAgent.includes('chrome')) {
    return 'chrome' as const;
  } else if (userAgent.includes('firefox')) {
    return 'firefox' as const;
  } else if (userAgent.includes('opera') || userAgent.includes('opr/')) {
    return 'opera' as const;
  } else if (userAgent.includes('safari')) {
    return 'safari' as const;
  }

  return 'unknown' as const;
};
